
/* eslint-disable @typescript-eslint/no-explicit-any */

import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { useStore } from "vuex";
import { StepResult } from "@/store/captain/CaptainModule";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

import StudentSelector from "@/views/people/student/selector/Student.vue";
import {
  StaticRoomActions,
  StaticRoomCustomerStatus,
} from "@/store/vm/static-room/enums";

export default defineComponent({
  name: "insert-students-to-class",
  components: {
    StudentSelector,
  },
  emits: ["insertSuccess"],
  props: {
    staticRoomId: {
      type: Number,
      default: 0,
    },
    allowMultiStudent: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      btnShowModalId: "btnShowModalIdInsertCustomerToStaticRoomModal",
      modalId: "modalInsertCustomerToStaticRoomModal",
    };
  },
  methods: {
    activate: function () {
      document.getElementById(this.btnShowModalId)?.click();
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const staticRoomIdTarget = ref(0);
    const peopleIdTarget = ref<number[]>([]);
    const studentSelectorRef = ref();

    const insertCustomerToStaticRoomModalRef = ref<null | HTMLElement>(null);

    const staticRoomId = computed(() => {
      return props.staticRoomId;
    });

    onMounted(() => {
      apply();
    });

    watch([staticRoomId], () => {
      apply();
    });

    const apply = () => {
      staticRoomIdTarget.value = props.staticRoomId;
    };

    const submit = async () => {
      const staticRoomId = staticRoomIdTarget.value;
      const studentId = studentSelectorRef.value.get(); // = peopleId

      const result: StepResult = await store
        .dispatch(StaticRoomActions.UPDATE_CUSTOMER_STATUS, {
          staticRoomId: parseInt(staticRoomId as any),
          peopleId: studentId,
          status: StaticRoomCustomerStatus.ACTIVE,
        })
        .catch((e) => {
          console.log(e);
        });

      if (result.isSuccess) {
        emit("insertSuccess");
        Swal.fire({
          position: "top-end",
          text: result.data || "Success!",
          icon: "success",
          buttonsStyling: false,
          timer: 2000,
          width: 400,
          showCancelButton: false,
          showConfirmButton: false,
        });

        hideModal(insertCustomerToStaticRoomModalRef.value);
      } else {
        Swal.fire({
          text: result.data || "Error",
          icon: "error",
          buttonsStyling: false,
          timer: 2000,
          width: 400,
          showCancelButton: false,
          showConfirmButton: false,
        });
      }
    };

    const onChange = (newVal) => {
      staticRoomIdTarget.value = newVal.value;
    };

    return {
      insertCustomerToStaticRoomModalRef,
      submit,
      apply,
      peopleIdTarget,
      staticRoomIdTarget,
      onChange,
      studentSelectorRef,
    };
  },
});
