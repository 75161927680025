import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createBlock(_component_el_select, {
    modelValue: _ctx.studentIds,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.studentIds) = $event)),
    multiple: _ctx.allowMultiple,
    filterable: "",
    remote: "",
    "reserve-keyword": "",
    placeholder: "Nhập tên, ID, email hoặc số điện thoại để tìm kiếm",
    "remote-method": _ctx.remoteMethod,
    loading: _ctx.loading
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item) => {
        return (_openBlock(), _createBlock(_component_el_option, {
          key: item.value,
          label: item.label,
          value: item.value
        }, null, 8, ["label", "value"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["modelValue", "multiple", "remote-method", "loading"]))
}