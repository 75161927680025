
import { StepResult } from "@/store/captain/CaptainModule";
import { StudentActions } from "@/store/vm/people/student/enums";
import { computed, defineComponent, ref, watch } from "vue";
import { useStore } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

interface OptionSelect {
  label: string;
  value: string | number;
}

export default defineComponent({
  name: "student-selector",
  emits: ["change"],
  props: {
    name: {
      type: String,
      default: "studentId",
    },
    value: {
      type: Array,
      default: () => [],
    },
    allowMultiple: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const store = useStore();
    const options = ref<OptionSelect[]>([]);
    const studentIds = ref<number[]>([]);
    const loading = ref(false);

    const valueProp = computed(() => {
      return props.value;
    });

    watch(valueProp, (currentValue) => {
      if (currentValue) {
        // eslint-disable-next-line
        studentIds.value = currentValue as any;
      }
    });

    const remoteMethod = async (query: string) => {
      if (query) {
        loading.value = true;

        const result: StepResult = await store
          .dispatch(StudentActions.SEARCH_BY_NAME_OR_ID_OR_PHONE_OR_EMAIL, {
            keyword: query,
          })
          .catch((e) => {
            console.log(e);
          });

        if (result.isSuccess) {
          const liveData: OptionSelect[] = [];
          // eslint-disable-next-line
          const data: Record<string, any>[] = result.data as any;
          data.map((m) => {
            const txtRole = m.type === "student" ? "Học viên" : "Khách";
            liveData.push({
              label: `${m.first_name} ${m.last_name} ( ID: ${m.id} - ${
                m.email || m.phone
              }) - ${txtRole} `,
              value: m.id,
            });
          });
          loading.value = false;
          options.value = liveData;
        } else {
          Swal.fire({
            text: "Đã xảy ra lỗi, vui lòng thử lại sau",
            icon: "error",
            buttonsStyling: false,
            timer: 2000,
            width: 400,
            showCancelButton: false,
            showConfirmButton: false,
          });
          options.value = [];
        }
      } else {
        options.value = [];
      }
    };

    const get = () => {
      return studentIds.value;
    };

    return {
      options,
      loading,
      remoteMethod,
      studentIds,
      get,
    };
  },
});
