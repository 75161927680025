
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import moment from "moment";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { StepResult } from "@/store/captain/CaptainModule";
import {
  StaticRoomActions,
  StaticRoomCustomerStatus,
} from "@/store/vm/static-room/enums";

export default defineComponent({
  name: "customer-list",
  components: {},
  props: {
    staticRoomId: Number,
  },
  emits: ["insertCustomer"],
  data() {
    return {
      loadingModule: true,
      moment: moment,
    };
  },
  setup(props, { emit }) {
    const store = useStore();

    const staticRoomName = ref("");
    const list = ref([]);

    const count = computed(() => {
      return list.value.length;
    });

    const genderLabels = {
      0: "Nam",
      1: "Nữ",
      2: "Khác",
    };

    onMounted(async () => {
      await apply();
    });

    const apply = async () => {
      const detail = await store
        .dispatch(StaticRoomActions.GET_DETAIL_STATIC_ROOM, props.staticRoomId)
        .catch((e) => {
          console.log(e);
        });
      staticRoomName.value = detail.name;

      // TODO: add pagination
      const result: StepResult = await store
        .dispatch(StaticRoomActions.GET_LIST_CUSTOMER, {
          staticRoomId: props.staticRoomId,
          page: 1,
          limit: 200,
        })
        .catch((e) => {
          console.log(e);
        });

      console.log(result);

      if (result.isSuccess) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        list.value = result.data as any;
      } else {
        console.error(
          "Xảy ra lỗi trong quá trình lấy danh sách khách hàng của Thiền phòng tĩnh"
        );
        console.log(result);
      }
    };

    const onDel = (peopleId) => {
      Swal.fire({
        title: "Bạn muốn xóa người dùng này khỏi thiền phòng tĩnh?",
        text: "Học viên sẽ KHÔNG THỂ truy cập vào các tài nguyên của thiền phòng tĩnh cho tới khi được active lại.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const res: StepResult = await actionDelete(peopleId);
          console.log("res");
          console.log(res);
          if (res.isSuccess) {
            Swal.fire({
              position: "top-end",
              text: res.data || "Success!",
              icon: "success",
              buttonsStyling: false,
              timer: 2000,
              width: 400,
              showCancelButton: false,
              showConfirmButton: false,
            });
            await apply();
          } else {
            Swal.fire({
              text: res.data || "Error",
              icon: "error",
              buttonsStyling: false,
              timer: 2000,
              width: 400,
              showCancelButton: false,
              showConfirmButton: false,
            });
          }
        }
      });
    };

    const onBand = (peopleId) => {
      Swal.fire({
        title: "Bạn muốn khóa người dùng này?",
        text: "Người dùng không thể truy cập tài nguyên này. Trạng thái khóa dùng để đánh dấu hành động vi phạm chính sách nghiêm trọng, phân biệt với Inactive thông thường.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const res: StepResult = await actionBand(peopleId);
          console.log("res");
          console.log(res);
          if (res.isSuccess) {
            Swal.fire({
              position: "top-end",
              text: res.data || "Success!",
              icon: "success",
              buttonsStyling: false,
              timer: 2000,
              width: 400,
              showCancelButton: false,
              showConfirmButton: false,
            });
            await apply();
          } else {
            Swal.fire({
              text: res.data || "Error",
              icon: "error",
              buttonsStyling: false,
              timer: 2000,
              width: 400,
              showCancelButton: false,
              showConfirmButton: false,
            });
          }
        }
      });
    };

    const actionDelete = async (peopleId) => {
      const result = await store
        .dispatch(StaticRoomActions.UPDATE_CUSTOMER_STATUS, {
          staticRoomId: props.staticRoomId,
          peopleId,
          status: StaticRoomCustomerStatus.INACTIVE,
        })
        .catch((e) => {
          console.log(e);
        });
      return result;
    };

    const actionBand = async (peopleId) => {
      const result = await store
        .dispatch(StaticRoomActions.UPDATE_CUSTOMER_STATUS, {
          staticRoomId: props.staticRoomId,
          peopleId,
          status: StaticRoomCustomerStatus.LOCK,
        })
        .catch((e) => {
          console.log(e);
        });
      return result;
    };

    const clickInsert = () => {
      emit("insertCustomer");
    };

    const statusLabels = {
      0: "Active",
      1: "Inactive",
      2: "Khóa",
    };

    return {
      genderLabels,
      list,
      count,
      onDel,
      onBand,
      apply,
      clickInsert,
      statusLabels,
      staticRoomName,
    };
  },
});
