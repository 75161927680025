import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ListCustomerInStaticRoom = _resolveComponent("ListCustomerInStaticRoom")!
  const _component_InsertCustomerToStaticRoom = _resolveComponent("InsertCustomerToStaticRoom")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ListCustomerInStaticRoom, {
      staticRoomId: _ctx.staticRoomId,
      onInsertCustomer: _cache[0] || (_cache[0] = ($event: any) => (_ctx.activateInsertStaticRoom())),
      ref: "listCustomerInStaticRoomRef"
    }, null, 8, ["staticRoomId"]),
    _createVNode(_component_InsertCustomerToStaticRoom, {
      staticRoomId: _ctx.staticRoomId,
      peopleId: [],
      ref: "insertCustomerToStaticRoomRef",
      onInsertSuccess: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onInsertSuccess()))
    }, null, 8, ["staticRoomId"])
  ], 64))
}