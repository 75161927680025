
import ListCustomerInStaticRoom from "@/views/static-room/customer/List.vue";
import InsertCustomerToStaticRoom from "@/views/static-room/customer/InsertCustomerToStaticRoom.vue";
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "static-room-customer-index",
  components: {
    ListCustomerInStaticRoom,
    InsertCustomerToStaticRoom,
  },
  setup() {
    const route = useRoute();
    const staticRoomId = route.params.staticRoomId;

    const insertCustomerToStaticRoomRef = ref();
    const listCustomerInStaticRoomRef = ref();

    const activateInsertStaticRoom = () => {
      insertCustomerToStaticRoomRef.value.activate();
    };

    const onInsertSuccess = () => {
      listCustomerInStaticRoomRef.value.apply();
    };

    return {
      insertCustomerToStaticRoomRef,
      listCustomerInStaticRoomRef,
      activateInsertStaticRoom,
      onInsertSuccess,
      staticRoomId,
    };
  },
});
